<template>
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" :class="{'toggled':sidebar}" id="accordionSidebar">
        <div class="sidebar-brand d-flex align-items-center ">
                <!-- Sidebar Toggle (Topbar) -->
                <button class="sm-none-b" id="sidebarToggleTop" @click="btntoggle">
                    <i :class="{'fa fa-bars':!sidebar, 'fa fa-times' : sidebar}"></i>
                </button>
                <!-- Sidebar - Brand -->
                <a href="javascript:void(0)" @click="dashboard">
                    <div class="sidebar-brand-icon rotate-n-15">
                        <router-link to="/dashboard">
                        <img :src="company_setting ? company_setting.logo : require('@/assets/backend/img/logo-top.png')" alt="" class="logo">
                        </router-link>
                    </div>
                </a>
            </div>
       
            <v-list>
                
                <v-list-group no-action v-for='navLink in navLinks' :key="navLink.text" :append-icon="navLink.subLinks ? '$expand' : ''" :class="{none: navLink.route == '/useradmin' && user.type == 'admin'}">
                    <v-list-item slot='activator' :to="navLink.route">
                        <v-list-item-icon>
                            <v-icon>{{ navLink.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ navLink.text }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-for='sub in navLink.subLinks' :key="sub.title">
                        <router-link class="text-white" :to="sub.route"><v-list-item-title>{{ sub.title }}</v-list-item-title></router-link>
                    </v-list-item>
                </v-list-group>
            </v-list>
    </ul>
</template>

<script>
import {mapActions,mapState} from 'vuex'
export default {
    data: () => ({ 
        drawer:true,
        user: {},
        items: [
            { title: "Dashboard", icon: "mdi-view-dashboard", to: "/dashboard" },
            { title: "Categories", icon: "mdi-image", to: "/dashboard/categories" },
            { title: "Brands", icon: "mdi-help-box", to: "/dashboard/brands" },
            { title: "Products", icon: "mdi-help-box", to: "/dashboard/products" },
        ],
        company :{},
        navLinks: [
                { icon: "dashboard", text: "Dashboard",route: "/dashboard" },
                {
                    icon: "fa-solid fa-users",
                    text: "Employees",
                    subLinks: [
                        { title: "Department", route: "/department" },
                        { title: "Designation", route: "/designation" },
                        { title: "Employee", route: "/employee" },
                    ]
                },
                { icon: "fa-solid fa-user", text: "Admin", route:"/useradmin" },
                {
                    icon: "fa-regular fa-clock",
                    text: "Rota",
                    subLinks: [
                        { title: "Rota Management", route: "/roaster_management" },
                        { title: "Rota Swap", route: "/roaster_swap" },
                        { title: "Rota Report", route: "/roaster_report"},
                    ]
                },
                {
                    icon: "fa-solid fa-pen",
                    text: "Time Sheet",
                    subLinks: [
                        { title: "Time Sheet Entry", route: "/add-attendance" },
                        { title: "View Time Sheet", route: "/attendance" },
                    ]
                },
                {
                    icon: "fa-solid fa-money-bill",
                    text: "Salary",
                    subLinks: [
                        { title: "Miscellaneous", route: "/miscellaneous" },
                        { title: "Salary Process", route: "/salary_process" },
                        { title: "Payments", route: "/payments" },
                        { title: "Provident Fund", route: "/provident_fund"},
                        { title: "Salary Summary", route: "/salary_summary"},
                    ]
                },
                { icon: "fa-solid fa-paper-plane", text: "Leave Application", route:"/leave" },
                { icon: "fa-solid fa-paper-plane", text: "Schedule", route:"/schedule"},
                { icon: "fa-solid fa-menorah", text: "Holidays", route:"/holidays"},
                { icon: "fa fa-cogs", text: "Job Card", route:"/job_card"},
                {
                    icon: "fa-regular fa-file",
                    text: "Report",
                    subLinks: [
                        { title: "Attendance Report", route: "/attendance_report" },
                        { title: "Salary Report", route: "/salary_slip" },
                    ]
                },
                {
                    icon: "fa-solid fa-gears",
                    text: "Settings",
                    subLinks: [
                        // { title: "Salary Settings", route: "/salary_setting" },
                        { title: "Company Settings", route: "/company_settings" },
                    ]
                },
                {
                    icon: "fa-solid fa-gears",
                    text: "Invoice",
                    subLinks: [
                        // { title: "Salary Settings", route: "/salary_setting" },
                        { title: "Invoice", route: "/invoice" },
                    ]
                },
            ]
    }),
    props:{
        sidebar: false
    },
    async created() {
        this.CompanySettingsDetails();
        this.getUser();
        this.filterItems();
    },
    computed: {
        currentRouteName() {
            return this.$route.path;
            },
            ...mapState("company_settings", ["company_setting"])
    },
  methods: {

    ...mapActions("company_settings", ["CompanySettingsDetails"]),
    dashboard(){
        this.$router.push('/dashboard').catch(()=>{})
    },
    filterItems(){
        if(this.company_setting && this.company_setting.attendance_type === "regular"){
            this.navLinks = this.navLinks.filter((link)=> link.text !== "Rota");
        }
        else{
            this.navLinks = this.navLinks.filter((link)=> (link.text !== "Schedule") && (link.text !== "Invoice"));
        }
    },
    getUser(){
        this.user = JSON.parse(localStorage.getItem("user_data"))
    },
    setCollapse(){
        this.addClass('test');
    },
    btntoggle(){
        this.$emit('toggleSidebar')
    },
    routePage(page) {
    switch(page) {
     case 'Home':
this.$router.push({ path: '/', query: {} });
break;
case 'My Account':
//
break;
case 'Users':
//
}
},
    getCompanySettingsDetails() {
      this.$store
        .dispatch(
          "company_settings/CompanySettingsDetails",
          this.$route.params.id
        )
        .then((res) => {
          if (res) {
            (this.company.id = res.id),
              (this.company.name = res.name),
              (this.company.web_site_link = res.web_site_link),
              (this.company.email = res.email),
              (this.company.xero_client_id = res.xero_client_id),
              (this.company.xero_client_secret = res.xero_client_secret),
              (this.company.phone = res.phone),
              (this.company.address = res.address),
              (this.emp_image = res.logo),
              (this.company.status = res.status);
          }
        });
    },
  }
}
</script>

<style>
    .menu_color {
        background-color: whitesmoke;
        color: black;
    }
    #accordionSidebar .theme--light.v-list{
        background-color: transparent!important;
    }
    #accordionSidebar .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        color: rgba(255, 255, 255, 255);
    }
    #accordionSidebar .theme--light.v-icon {
        color: rgba(255, 255, 255, 255);
    }
    #accordionSidebar .v-application--is-ltr .v-list-item__action:first-child,
    .v-application--is-ltr .v-list-item__icon:first-child {
        margin-right: 15px;
    }


    #accordionSidebar .v-list-item__action, 
    .v-list-item__avatar, 
    .v-list-item__icon {
        display: inline-flex;
        min-width: 16px;
    }
    #accordionSidebar .mdi-chevron-down::before {
        content: "\F0140";
        color: #fff;
    }
    #accordionSidebar .v-list-item__title {
        font-size: .85rem;
    }
    .v-list-item {
        padding: 0 10px;
    }
    .sidebar.toggled {
        overflow: visible;
        width: 7rem !important;
    }
   .sidebar.toggled .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
        margin: 0;
    }
    .sidebar.toggled .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
        position: absolute;
        left: 7.25rem;
        z-index: 1;
        top: 2px;
        animation-name: growIn;
        animation-duration: .2s;
        animation-timing-function: transform cubic-bezier(.18,1.25,.4,1),opacity cubic-bezier(0,1,.4,1);
    }
   
    .sidebar.toggled .v-list-item {
        display: block;
        padding: 5px 10px;
        position: relative;
        min-width: 100px;
        text-align: center;
    }
    .sidebar.toggled .v-list-item__icon {
        margin: 5px 0;
        
    }
    .sidebar.toggled .v-list-group__items {
        position: absolute;
        left: 7.25rem;
        z-index: 1;
        top: 2px;
        box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%);
        background: #fff;
        min-width: 150px;

    }
    .sidebar.toggled .v-list-group__items  .v-list-item__title {
        font-size: .85rem;
        color: #333;
    }
   
    .sidebar.toggled .v-list-group--no-action > .v-list-group__items > .v-list-item {
            padding-left: 15px;
            min-height: 0px;
            padding-top: 10px;
            padding-bottom: 10px;

    }
    .sidebar.toggled  .v-list-group.v-list-group--active.v-list-group--no-action.primary--text {
        position: relative;
    }
    .v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child), 
    .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), 
    .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
        margin-left: 2px;
    }
    .v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
        padding-left: 45px;
    }
    .sidebar.toggled i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light{
        display: none;
    }
    #accordionSidebar .v-icon.v-icon{
        font-size: 16px;
    }
    #accordionSidebar .router-link-active{
        color: #0d6efd!important;
    }
    #accordionSidebar .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon{
        min-width: 60px;
    }
    .none{
        display: none;
    }
    div#content_wrapper{
        overflow: hidden!important;
    }
  
</style>
